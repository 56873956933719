import { Request } from "services/_base";
import { IService, Service } from "services/types";

import {
  GET_PROJECTS_URL,
  CREATE_PROJECT_URL,
  DELETE_PROJECT_URL,
  ARCHIVE_PROJECT_URL,
  GET_LAST_PROJECTS,
  GET_PROJECT_USERS_URL,
  POST_UNDO_SHARE_PROJECT_URL,
  POST_SHARE_PROJECT_URL,
  UPDATE_PROJECT_NAME_URL,
  GET_PROJECT_URL,
} from "./constants";
import {
  GetProjectsServiceResponse,
  GetProjectsServiceVariables,
  CreateProjectServiceVariables,
  DeleteProjectServiceVariables,
  DeleteProjectServiceResponse,
  ArchiveProjectServiceVariables,
  ArchiveProjectServiceResponse,
  CreatetProjectServiceResponse,
  GetProjectUserServiceResponse,
  GetProjectUserServiceVariables,
  ShareProjectServiceVariables,
  ShareProjectServiceResponse,
  UndoShareProjectServiceVariables,
  UndoShareProjectServiceResponse,
  UpdateProjectServiceVariables,
  UpdateProjectServiceResponse,
  GetProjectServiceVariables,
  GetProjectServiceResponse,
  GetLastProjectsServiceResponse,
} from "./types";

export const getProjects: IService<
  GetProjectsServiceVariables,
  GetProjectsServiceResponse
> = (params) => {
  return Request.get(GET_PROJECTS_URL, params);
};

export const createProject: Service<
  CreateProjectServiceVariables,
  CreatetProjectServiceResponse
> = ({ data }) => {
  return Request.post(CREATE_PROJECT_URL, data);
};

export const updateProjectName: IService<
  UpdateProjectServiceVariables,
  UpdateProjectServiceResponse
> = ({ data }) => {
  return Request.put(UPDATE_PROJECT_NAME_URL, data, {});
};
export const deleteProject: Service<
  DeleteProjectServiceVariables,
  DeleteProjectServiceResponse
> = ({ params }) => {
  return Request.delete(DELETE_PROJECT_URL, params);
};

export const archiveProject: Service<
  ArchiveProjectServiceVariables,
  ArchiveProjectServiceResponse
> = ({ data, params }) => {
  return Request.put(ARCHIVE_PROJECT_URL, data, { params });
};

export const getLastProjects: IService<
  null,
  GetLastProjectsServiceResponse
> = () => {
  return Request.get(GET_LAST_PROJECTS, null);
};

export const getProjectsUser: Service<
  GetProjectUserServiceVariables,
  GetProjectUserServiceResponse
> = (params) => {
  return Request.get(GET_PROJECT_USERS_URL, params);
};

export const shareProject: Service<
  ShareProjectServiceVariables,
  ShareProjectServiceResponse
> = ({ data }) => {
  return Request.post(POST_SHARE_PROJECT_URL, data);
};

export const undoShareProject: Service<
  UndoShareProjectServiceVariables,
  UndoShareProjectServiceResponse
> = ({ data }) => {
  return Request.post(POST_UNDO_SHARE_PROJECT_URL, data);
};

export const getProject: IService<
  GetProjectServiceVariables,
  GetProjectServiceResponse
> = ({ data }) => {
  return Request.get(GET_PROJECT_URL, data);
};
