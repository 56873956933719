import React from "react";
import classes from "./style.module.css";
import { LocationProps } from "./types";

const Location: React.FunctionComponent<LocationProps> = ({
  isActive,
  locationData,
  onClick,
  icon,
}) => {
  const getLocation = () => {
    onClick(locationData);
  };
  return (
    <div
      className={`${classes.location_container} ${
        isActive ? classes.isActive : ""
      }`}
      onClick={(e) => {
        e.stopPropagation();
        getLocation();
      }}
    >
      {icon}
      {locationData?.name}
    </div>
  );
};

export default Location;
