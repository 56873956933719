export const GET_PROJECTS_URL = "projects";
export const CREATE_PROJECT_URL = "projects";
export const DELETE_PROJECT_URL = "project";
export const ARCHIVE_PROJECT_URL = "project/archive";
export const GET_LAST_PROJECTS = "projects/last";
export const GET_PROJECT_USERS_URL = "project/users";
export const POST_SHARE_PROJECT_URL = "share/project";
export const POST_UNDO_SHARE_PROJECT_URL = "share/project/undo";
export const UPDATE_PROJECT_NAME_URL = "/project/name";
export const GET_PROJECT_URL = "/project";
